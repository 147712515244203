import Swiper from "swiper";

function swiper($selector, $options) {
  return new Swiper($selector, $options);
}


swiper('.services__swiper', {
  slidesPerView: 1,
  spaceBetween: 30,
  breakpoints: {
    992: {
      slidesPerView: 3,
    },

    768: {
      slidesPerView: 2,
    },
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
